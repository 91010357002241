import logoImage from './assets/img/logo.png';
import headerImage from './assets/img/header.jpg';
import lastMinuteImage from './assets/img/last-minute.png';
import fontFamily from './assets/fonts/Nunito-Regular.ttf';

(window as any).config = {
    uid: 'efood',
    logoImage: logoImage,
    headerImage: headerImage,
    lastMinute: {
        text: 'A következő választékunk rendelhető a készlet erejéig (Fontos, hogy a Last minute során megrendelt ételek lemondására nincs lehetőség. Kérjük, fokozott figyelemmel adjanak le rendelést és válasszanak fizetési módot!): A betűjel végén szereplő „K” kis adagot jelöl. Szombati ételeink pénteken kerülnek kiszállításra.',
        image: lastMinuteImage
    },
    projectName: 'e-Food',
    projectSlogan: 'Frissen főzve, frissen hűtve',
    loginWelcome: 'Üdv újra az e-Food weblapján, kérem jelentkezzen be!',
    pdf: {
        fontFamily: fontFamily
    },
    constans: {
        CONST_URL_FOOLDAL: 'https://e-food.hu/',
        CONST_URL_GYIK: 'https://e-food.hu/gyakori-kerdesek/',
        CONST_URL_KAPCSOLAT: 'https://e-food.hu/kapcsolat/',
        CONST_URL_TUDNIVALOK: 'https://e-food.hu/tudnivalok/',
        CONST_URL_SZALLITASI_TERULETEK: 'https://e-food.hu/szallitasi-teruletek/',
        CONST_URL_ADATVEDELMI: 'https://e-food.hu/adatkezelesi-tajekoztato/',
        CONST_URL_ASZF: 'https://e-food.hu/altalanos-szerzodesi-feltetelek/'
    },
    urls: {
        prod: 'https://ea.e-food.hu',
        dev: 'https://efood.dev.diff.hu',
        local: 'http://localhost:10001'
    },
    bugsnag: {
        api: 'c2ede0634a132c8678d82da92e32e93f'
    },
    payment_methods: ['cash', 'barion', 'mkb_szep_card', 'otp_szep_card', 'kh_szep_card', 'cib'],
    hasMultilingualMenu: false
};

import './variables.scss';
import './special.scss';
import('../../src/main')
    .then(module => {
        // The module is now loaded and executed
        console.log(`[${window.config?.uid ?? ''}] Main module loaded!`);
    })
    .catch(error => {
        console.error('❌ Error loading main module:', error);
    });
